
$primary-color: #ED1B23
.nav-bar
  height: 120px
  display: flex
  align-items: center
  position: relative
  z-index: 10
  transition: all 0.3s

  .mobile-nav-btn
    display: none
  
  .nav-content
    display: flex
    justify-content: space-between
    align-items: baseline
    overflow-y: hidden

    #logo
      display: inline-block

    .menu
      list-style: none
      padding: 0
      margin: 0
      display: inline-flex

      .menu-item
        padding: 0 15px
        font-weight: bold
        font-size: 18px
        height: 100%
        cursor: pointer

        a
          color: inherit
          display: block
          width: 100%

        &:hover .sub-menu
          display: block

    .sub-menu
      position: absolute
      list-style: none
      padding: 0
      margin: 0
      background: white
      top: 100%
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25)
      display: none

      .sub-menu-item
        min-height: 50px
        display: flex
        align-items: center
        padding: 10px 20px
        font-weight: normal
        cursor: pointer
        line-height: 2em

        a
          color: inherit

        &:hover
          background: $primary-color
          color: white
      &::before
        width: 100px
        height: 40px
        position: absolute
        top: -40px
        left: 0
        content: ''

  &.fixed
    position: fixed
    top: 0
    left: 0
    width: 100%
    background: white

  &.mobile
    padding: 0 20px
    top: 0
    left: 0
    overflow-y: hidden
    width: 100%
    height: 100px
    background: white
    &.open
      height: 100vh
      overflow-y: auto
    .nav-content
      display: block
    #logo
      display: block
      position: absolute
      top: 10px
      left: 10px
    .mobile-nav-btn
      display: block
      position: absolute
      top: 40px
      right: 30px
      color: $primary-color
      font-size: 24px
      cursor: pointer
      border: none
      background: transparent
    .menu-container
      width: 100%
      height: calc(100vh - 100px)
      position: absolute
      top: 100px
      left: 0
      overflow-y: auto
    .menu
      flex-direction: column
      .menu-item
        min-height: 50px
        display: flex
        flex-direction: column
        justify-content: center
      .sub-menu
        display: inline-block
        position: relative
        box-shadow: none
        margin: 20px 0

.nav-placeholder
  height: 120px
  &.mobile
    height: 100px
