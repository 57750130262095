@import 'components/collapsible'

html.mobile,body.mobile
  font-size: 14px
  .ui.buttons
    font-size: 1em

*, h1, h2, h3, h4, h5, h6
  font-family: 'Work Sans'

h1, h2, h3, h4, h5, h6
  margin: calc(2rem - .14285714em) 0 1rem!important

th, td
  white-space: pre-wrap

.container
  width: 1200px
  margin: 0 auto

.fslightbox-open
  height: auto!important

.fslightbox-open
  height: auto!important

.header
  background: white
  .logo
    width: 70%
    margin: 0 auto
    display: block
  .logo.mobile
    width: 100%
  .main-nav
    padding: 0 15%
    border-radius: 0
  .main-nav.mobile
    padding: 0
    font-size: 1.2em
    border-radius: 0
.main
  width: 70%
  margin: 0 15%
  margin-top: 20px
  font-size: 1.2em
  line-height: 1.8em
  padding: 20px
  p
    line-height: 1.8em
  .p-header
    color: white
    *
      color: white!important

.main.mobile
  width: 100%
  margin: 0
  h1, h2, h3, h4
    font-size: 1.5em
  .button
    font-size: 0.8em
  p
    font-size: 1.3rem

.footer
  width: 100%
  background: #db2828
  padding: 50px calc(15% + 20px)
  color: white
  font-size: 1.1em
  box-sizing: border-box
  h2
    margin-bottom: 20px
  a
    color: inherit
  ul
    list-style: none
    margin: 0
    padding: 0
    font-size: 1.1em
    li
      line-height: 2em
      cursor: pointer
      transition: all 0.2s
      &:hover
        padding-left: 10px
  .copyright
    margin-top: 20px
    text-align: right
  &.mobile
    padding: 50px 1rem
    margin-top: 20px
    .copyright
      text-align: left
      margin-top: -50px
    .column
      margin-bottom: 50px!important

h1
  color: #db2828
  margin-bottom: 30px

.text-tiny
  font-size: 14px

.text-big
  font-size: 30px
  line-height: 1.8em

.plan-card
  .divider
    width: 100px
    margin: 0 auto
    height: 1px
    background: grey
  .prop-title
    font-size: 0.8em
    font-weight: bold
    margin-top: 20px!important
  .prop-value
    font-size: 1.3em
