footer
  padding: 80px 0 40px 0
  h2
    margin-bottom: 20px
  a
    color: inherit
  ul
    list-style: none
    margin: 0
    padding: 0
    font-size: 18px
    margin-bottom: 80px
    li
      line-height: 2em
      cursor: pointer
      transition: all 0.2s
      &:hover
        padding-left: 10px
  .logo
    margin-bottom: 50px
    &.mobile
      margin-left: auto
      margin-right: auto
      display: block
