.collapsible
  list-style: none
  font-size: 18px
  .item
    border-bottom: 1px solid rgba(0, 0, 0, 0.25)
    &-title
      font-weight: bold
      min-height: 70px
      display: flex
      align-items: center
      justify-content: space-between
      margin: 0
      position: relative
      padding: 10px 10px
      box-sizing: border-box
      cursor: pointer
      line-height: 2em
      .down-icon
        font-size: 12px
        color: rgba(0, 0, 0, 0.5)
    &-content
      padding: 0 10px
      box-sizing: border-box
      overflow: hidden
      transition: max-height 0.5s
      transition-delay: 0
      max-height: 0
      &, & *
        line-height: 1.8em
    &.active .item-content
      max-height: 1000px
    &.active .down-icon
      transform: rotate(180deg)
