$primary-color: #ED1B23

.home-page
  section
    .title
      font-size: 36px
      margin-bottom: 80px!important

  .introduction
    .text-block
      width: 50%
      margin-top: 50px
      h1
        font-size: 64px
        color: #ED1B23
      .short-intro
        font-size: 24px
        margin-top: 30px
        line-height: 2em
      .important-message *
        color: #ED1B23!important
      .call-to-action
        padding: 10px 0
        margin-top: 50px
        .btn
          padding: 20px 40px
          border: none
          background: transparent
          font-size: 24px
          font-family: "Work Sans", sans-serif
          outline: none
          cursor: pointer
          &.active
            background: $primary-color
            color: white
            box-shadow: 0px 2px 5px rgba($primary-color, 0.5)
            border-radius: 5px
          &#contact
            color: $primary-color
    .intro-image
      height: 80vh
      position: absolute
      right: 0
      top: 0
      border-bottom-left-radius: 200px
      z-index: 0

    .partner
      margin-top: 200px
      .partner-label
        font-size: 24px
        font-weight: bold
        color: rgba(#000, 0.5)
        vertical-align: middle

      .partners
        list-style: none
        padding: 0
        margin: 0
        display: inline-flex
        vertical-align: middle
        margin-left: 50px
        li
          margin-left: 100px
          display: flex
          align-items: center
  
  .solution
    padding-top: 100px
    .title
      text-align: center
    .description
      text-align: center
      font-size: 24px
      margin-bottom: 80px

    .services
      display: flex
      justify-content: center
      margin-bottom: 80px
      ul
        padding: 0
        margin: 0
        list-style: none
        display: flex

        li
          margin-right: 70px
          display: flex
          flex-direction: column
          align-items: center
          width: 132px
          text-align: center
          &:last-child
            margin-right: 0

          .solution-icon
            display: flex
            justify-content: center
            width: 100px
            height: 100px
            background: white
            border-radius: 50%
            box-shadow: 0px 2px 5px rgba($primary-color, 0.5)
            margin-bottom: 40px
            font-size: 36px
            align-items: center
            color: $primary-color
            img
              display: block

          .solution-name
            font-weight: bold
            font-size: 18px
            line-height: 1.5em
            
    .internet-services
      display: flex
      justify-content: space-between
      .internet-service
        width: calc(50% - 10px)
        position: relative
        img
          width: 100%
        .mask
          width: 100%
          height: 100%
          position: absolute
          content: ''
          background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #0F0F0F 100%)
        .content
          width: 100%
          position: absolute
          bottom: 0
          left: 0
          color: white
          padding: 20px
          h2
            font-size: 48px
          ul
            list-style: none
            padding: 0
            margin: 0
            font-size: 24px
            li
              margin: 40px 0
              i
                font-size: 15px
                margin-left: 15px
                transition: all 0.3s
              a
                color: inherit
              &:hover i
                margin-left: 30px


  .views-from-us
    padding-top: 120px
    .title
      text-align: center
    .short-description
      font-size: 24px
      text-align: center
      line-height: 2em
      width: 900px
      margin: 0 auto
      margin-bottom: 100px
    .follow-us
      font-size: 24px
      text-align: center
      font-weight: bold

  .faq
    padding-top: 120px
    .title
      text-align: center
    .short-description
      font-size: 24px
      text-align: center
      line-height: 2em

    .questions
      width: 900px
      font-size: 18px
      margin: 120px auto

  .contact
    position: relative
    .left, .right
      width: 50%
      display: inline-block
      vertical-align: top

    .left
      p
        width: 558px
        font-size: 24px
        line-height: 2em
    .contact-card
      padding: 60px
      box-sizing: border-box
      background: $primary-color
      color: white
      font-size: 24px
      box-shadow: 0 2px 15px rgba($primary-color, 0.5)
      z-index: 2
      position: relative
      top: 100px
      p
        line-height: 2em
      h2
        margin-bottom: 1.2em
    #map
      height: 600px
      width: 100%
      z-index: 1
      position: relative
      top: -420px
      border: none
      margin-bottom: -420px
  footer
    padding: 80px 0 40px 0
    h2
      margin-bottom: 20px
    a
      color: inherit
    ul
      list-style: none
      margin: 0
      padding: 0
      font-size: 18px
      margin-bottom: 80px
      li
        line-height: 2em
        cursor: pointer
        transition: all 0.2s
        &:hover
          padding-left: 10px

  &.mobile
    .container
      width: 100%
      padding: 0 20px
    .introduction
      .intro-image
        display: none
      .text-block
        width: 100%
        text-align: center
        margin-top: 20px
        h1
          font-size: 36px
          padding: 0 20px
        .short-intro
          font-size: 18px
          padding: 0 20px
          margin-top: 0
        .btn
          font-size: 16px
        .call-to-action
          display: inline-flex
          flex-direction: column
          margin: 0 auto
      .partner
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
        margin-top: 40px
        .partner-label
          font-size: 18px
          margin-bottom: 30px
        .partners
          margin: 0
          width: 100%
          display: block
          text-align: center
          li
            display: inline-flex
            margin-left: 0px
            padding: 0 20px
            &:last-child
              margin-right: 0
    .solution
      .container
        padding: 0
      .title
        padding: 0 40px
      .description
        padding: 0 20px
        line-height: 2em
      .services
        ul
          flex-wrap: wrap
          justify-content: center
          li
            display: inline-flex
            margin: 0
            width: 132px
            align-items: center
            margin-bottom: 30px
            .solution-icon
              width: 70px
              height: 70px
              font-size: 24px
              margin-bottom: 20px
              img
                width: 36px
            .solution-name
              font-size: 18px
              width: 80%
      .internet-services
        flex-direction: column
        .internet-service
          width: 100%
          img
            height: 100%
            width: auto
          .content
            h2
              font-size: 36px
            ul
              font-size: 18px
              li
                margin: 30px 0
    .views-from-us
      display: none
    .faq
      .title
        margin-bottom: 30px
      .collapsible
        width: 100%
        padding: 0
        margin: 100px 0
        margin-top: 0
    #contact-us
      .container
        padding: 0
      .title
        margin-bottom: 30px
        padding: 0 20px
      .left, .right
        width: 100%
      .left
        p
          width: 100%
          font-size: 18px
          padding: 0 20px
      .right
        .contact-card
          box-shadow: none
          top: 20px
          font-size: 18px
          padding: 30px 20px
      #map
        height: 300px
        top: 20px
        margin-bottom: 0
